<!-- 组织架构 -->
<template>
    <div>
        <ctrl-box class="ctrl-box" :data="{}">
            <template >
                <!-- <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt" >{{msg('搜索')}}</span>      
                        </div>                
                    </el-link>
                </div> -->
                <div class="xgroup">
                    <el-link :underline="false" @click="doAdd()" v-if="hasPermission('dept:create')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-tianjia" font-size="27px;"/>
                            <span class="txt">{{msg('添加')}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doEdit()" v-if="hasPermission('dept:update')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-bianji" font-size="27px;"/>
                            <span class="txt">{{msg('编辑')}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doEditCompany()" v-if="hasPermission('dept:company')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-xiangqingxiugai" font-size="27px;"/>
                            <span class="txt">{{msg('编辑公司信息')}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doGrantStore()" v-if="hasPermission('dept:grantStore')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-cangkuguanli" font-size="27px;"/>
                            <span class="txt">{{msg('分配仓库')}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doDelete()" v-if="hasPermission('dept:delete')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-shanchu1" font-size="27px;"/>
                            <span class="txt">{{msg('删除')}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doDeleteLogo()" v-if="hasPermission('dept:deleteLogo')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-shanchu1" font-size="27px;"/>
                            <span class="txt">{{msg('删除LOGO')}}</span>      
                        </div>            
                    </el-link>
                </div>
            </template>
        </ctrl-box>

        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="60px">
                <template v-slot:default="">
                    <!-- <el-form-item :label="msg('显示名')">
                        <el-input v-model="scope.query.label"></el-input>
                    </el-form-item> -->
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="getDepts" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" v-if="refresh" :height="docHeight"  
                show-overflow row-key
                border resizable
                row-id="id"
                :scroll-y="{gt: 60}"
                highlight-current-row highlight-hover-row
                :tree-config="{children: 'children', line: true, expandRowKeys: expandKeys}"
                :data="depts"
                :columns="columns"></vxe-grid>
        </div>

        <el-dialog :title="dgTitle" :visible.sync="dgVisible" :fullscreen="isMinScreen" :close-on-click-modal="false" @opened="openedEdit" >
            <dept-edit :depts="editDepts" :dept="curr"
                 :expand-keys="expandKeys"
                v-on:close-dg="closeEditDialog" />
        </el-dialog>

        <el-dialog title="编辑名称及联系方式" :visible.sync="dgVisible1"  :fullscreen="isMinScreen" :close-on-click-modal="false" @opened="openedEdit1">
            <dept-edit1 :id="detpId" :dept="dept" v-on:close-dg="closeEditDialog1" ref="editRef1"/>
        </el-dialog>

        <el-dialog :title="msg('分配仓库')" :visible.sync="grantStoreVisible" :fullscreen="isMinScreen" :close-on-click-modal="false" >
            <grant-store :dept="curr"
                v-on:close-dg="closeGrant" />
        </el-dialog>
    </div>
</template> 

<script>
import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";
import DeptEdit from "./DeptEdit.vue";
import DeptEdit1 from "./DeptEdit1.vue";
import GrantStore from "./DeptGrantStore.vue";
import XEUtils from 'xe-utils';

export default {
    name: 'Dept',
    components: {
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
        DeptEdit:DeptEdit,
        GrantStore: GrantStore,
        DeptEdit1:DeptEdit1
    },
    mounted(){
        this.user  = JSON.parse(localStorage.getItem("user"));
        this.buildColumns();
        this.getDepts();
    },
    data(){
        return {
            query:{
                label: null
            },
            showQuery: false,
            expandKeys: [],
           
            columns:[],
            depts: [],      //树形数据
            originDepts: [], //树形数据备份
            editDepts:[],
            origins: [],
            curr:null,

            refresh: true,

            dgTitle: '',
            dgVisible: false,

            grantStoreVisible: false,
            searchTxt: null,
            detpId:null,
            dept:null,
            dgVisible1:false,
            
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                return this.$store.state.isMinScreen;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 160;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    watch: {
        searchTxt(val) {
            this.serachTree();
        }
    },
    methods:{
        buildColumns(){

            let _this = this;
            this.columns = [
                {field: "label", title: this.msg("显示名"), 
                    treeNode: true, minWidth: this.isMinScreen?400:250, 
                    width: this.isMinScreen?400: 250,
                    slots: {
                        header: ({ row }) => {
                            return [
                                <el-input v-model={_this.searchTxt} placeholder={_this.msg("请输入节点名称搜索")}
                                    size="mini"
                                >
                                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                                </el-input>
                            ];
                        },
                    }

                },
                {field: "id", title: this.msg("ID"), width: 80},
                // {field: "path", title: this.msg("路径"), width: 150},
                {field: "name", title: this.msg("关键字"), minWidth:120,width:120},
                {field: "storeName", title: this.msg("所在仓库"), minWidth:120,width:120},
            ];
            if(this.hasPermission("dept:showPath")){
                this.columns.push({field: "labelPath", title: this.msg("显示路径"), minWidth: 150});
            }
            this.columns.push({field: "version", title: this.msg("系统版本"), minWidth: 100});
            if(this.hasPermission("dept:showCompany")){
                this.columns.push({field: "company", title: this.msg("系统名称"), minWidth: 100});
                this.columns.push({field: "title", title: this.msg("系统标题"), minWidth: 100});
                this.columns.push({field: 'logo', title: this.msg('系统logo'), minWidth: 100, sortable: true,
                    slots: {
                        default: ({ row }) => {
                                let id = row.logo
                                return id?[
                                    <el-image  style="width: 40px; height: 40px" 
                                        src={this.$kit.api.imgView+id} 
                                        preview-src-list={[this.$kit.api.imgView+id]}
                                        ></el-image>
                                ]: [id];
                            },
                    }
                });
                this.columns.push({field: "users", title: this.msg("工单联系人"), minWidth: 100});
                this.columns.push({field: "remark1", title: this.msg("联系1"), minWidth: 100});
                this.columns.push({field: "remark2", title: this.msg("联系2"), minWidth: 100});
                this.columns.push({field: "remark3", title: this.msg("联系3"), minWidth: 100});
            }
        },
        getDepts(){
            var params = this.$kit.rebuildQuery(this.query);
            this.$axios.post(this.$kit.api.dept.listByDataPath,params)
                .then((result) => {
                    if(result.status){
                        this.refresh = false;

                        var data = result.data;
                        this.origins = data;
                        var tree = this.buildTree(data);
                        this.setExpands(tree);

                        setTimeout(()=>{
                            this.originDepts = tree;
                            this.depts = tree;
                            this.refresh = true;
                        }, 100)
                    }
                });
        },
        reset(){
            this.$kit.clearQuery(this.query);
        },
        buildTree(roles){

            var map = {};
            roles.forEach(m => map[m.id] = m);
            
            for(let key in map){
                var m = map[key];
                if(m.parentId){ 
                    var parent = map[m.parentId];
                    if(parent){
                        if(!parent['children']){
                            parent['children'] = [];
                        }

                        parent.children.push(m);
                        m.parent = parent;
                    }
                }
            }

            var ms = [];

            for(let key in map){
                let m = map[key];
                if(m.parent == null){
                    ms.push(m);
                }
            }
            
            this.sortTree(ms);
            //ms.sort((a, b) => (b.seq || 0) - (a.seq || 0));

            return ms;
        },
        setExpands(depts){
            depts.forEach(e => {
                if(e.children && e.children.length<=10){
                    this.expandKeys.push(e.id);
                    this.setExpands(e.children);
                }
            });
        },
        openedEdit(){
            var params = this.$kit.rebuildQuery({label:null});
            this.$axios.post(this.$kit.api.dept.list,params)
                .then((result) => {
                    if(result.status){
                        this.refresh = false;
                        setTimeout(()=>{
                            var data = result.data;
                            this.origins = data;
                            var map = {};
                            data.forEach(m => map[m.id] = m);
                            
                            for(let key in map){
                                var m = map[key];
                                if(m.parentId){ 
                                    var parent = map[m.parentId];
                                    if(parent){
                                        if(!parent['children']){
                                            parent['children'] = [];
                                        }

                                        parent.children.push(m);
                                        m.parent = parent;
                                    }
                                }
                            }

                            var ms = [];

                            for(let key in map){
                                let m = map[key];
                                if(m.parent == null){
                                    ms.push(m);
                                }
                            }
                            
                            this.sortTree(ms);
                            this.editDepts = ms;
                            this.refresh = true;
                        }, 100)
                    }
                });
        },
        sortTree(ms){

            ms.sort((a, b) => (b.seq || 0) - (a.seq || 0));

            ms.forEach(m => {
                if(m['children'] && m.children.length>0){
                    this.sortTree(m['children']);
                }
            });

        },
        openedEdit1(){
            this.$nextTick(() => {
                this.$refs.editRef1.init();
            });
        },
        doAdd(){
            var row = this.$refs.dataTable.getCurrentRecord();
            if(!row){
                row = this.depts[0];
            }

            this.curr = {
                name: null,
                label: null,
                parentId: row.id,
                parentLabel: row.label,
            };

            this.dgTitle = this.msg("新增组织");
            this.dgVisible = true;

        },
        closeEditDialog(type){  //编辑弹窗关闭后的回调事件
            if(type){
                this.getDepts();
            }
            this.dgVisible = false;
        },
        doEdit(){
            var row = this.$refs.dataTable.getCurrentRecord();
            if(!row){
                this.$message(this.msg('请选中数据后再进行操作'));
                return;
            }

            this.curr = row;
            let parent = this.origins.find(d => d.id == row.parentId);
            if(parent){
                this.curr.parentId = parent.id;
                this.curr.parentLabel = parent.label;
            }
           
            this.dgTitle = this.msg("编辑组织")+":"+row.label;
            this.dgVisible = true;
        },
        doEditCompany(){
            var row = this.$refs.dataTable.getCurrentRecord();
            if(!row){
                this.$message(this.msg('请选中数据后再进行操作'));
                return;
            }
            this.detpId = row.id;
            this.dept = row;
            this.dgVisible1 = true;
        },
        closeEditDialog1(reload){
            this.dgVisible1 = false;
            if(reload){
                this.getDepts();
            }
        },
        doDelete(){
            var row = this.$refs.dataTable.getCurrentRecord();
            if(!row){
                this.$message(this.msg('请选中数据后在进行操作'));
                return;
            }

            var _this = this;
            this.$confirm(this.fmt('确定要删除节点《%s》吗?', [row.label]), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.get(_this.$kit.api.dept.delete+"?id="+row.id)
                .then((result) => {
                    _this.$message(result.msg || _this.msg('删除成功'));
                    _this.getDepts(true);
                });
            }).catch((err) => {
                // console.log(err);
            });
        },
        doDeleteLogo(){
            var row = this.$refs.dataTable.getCurrentRecord();
            if(!row){
                this.$message(this.msg('请选中数据后在进行操作'));
                return;
            }
            var _this = this;
            this.$confirm('确定要删除该节点的LOGO吗?', this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.get(_this.$kit.api.dept.deleteLogo+"?id="+row.id)
                .then((result) => {
                    _this.$message(result.msg || _this.msg('删除成功'));
                    _this.getDepts(true);
                });
            }).catch((err) => {
                // console.log(err);
            });
        },
        doGrantStore(){
            var row = this.$refs.dataTable.getCurrentRecord();
            if(!row){
                this.$message(this.msg('请选中数据后再进行操作'));
                return;
            }

            this.curr =  row;
            this.grantStoreVisible = true; 
        },
        closeGrant(type){
            if(type){
                this.getDepts();
            }
            this.grantStoreVisible = false;
        },
        serachTree(){
            let searchTxt = XEUtils.toString(this.searchTxt).trim()
            if (searchTxt) {
                let options = { children: 'children' }
                let searchProps = ['label']
                this.depts = XEUtils.searchTree(this.originDepts, item => searchProps.some(key => XEUtils.toString(item[key]).indexOf(searchTxt) > -1), options)
                // 搜索之后默认展开所有子节点
                this.$nextTick(() => {
                    this.$refs.dataTable.setAllTreeExpand(true)
                })
            } else {
                this.depts = this.originDepts
            }
        }
    }
}

</script>

<style scoped lang="scss">

.ctrl-box a{
    padding-left:5px; 
    padding-right:5px;
}
</style>