<!-- 菜单管理-->
<template>
    <div>
        <ctrl-box class="ctrl-box" :data="{}">
            <template>
                <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt" >搜索</span>      
                        </div>                
                    </el-link>
                </div>
                <div class="xgroup">
                    <el-link :underline="false" @click="doAdd()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-tianjia" font-size="27px;"/>
                            <span class="txt">添加</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doEdit()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-bianji" font-size="27px;"/>
                            <span class="txt">编辑</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" type="danger" @click="doDelete()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-shanchu1" font-size="27px;"/>
                            <span class="txt">删除</span>      
                        </div>            
                    </el-link>
                </div>
            </template>
        </ctrl-box>
 
        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" show-overflow row-key
                border
                resizable
                highlight-current-row
                :tree-config="treeConfig"
                :data="menus"
                :columns="columns"></vxe-grid>
        </div>

        <el-dialog :title="dgTitle" :visible.sync="dgVisible" :fullscreen="isMinScreen" :close-on-click-modal="false">
            <menu-edit :menus="origin" :menu="currMenu" :parent="parent"
                v-on:close-dg="closeEditDialog" ref="menuEdit"/>
        </el-dialog>
    </div>
   
</template>

<script>

import CtrlBox from "../../components/CtrlBox.vue";
import MenuEdit from "./MenuEdit.vue";
export default {
    name: 'Menu',
    components: {
        CtrlBox: CtrlBox,
        MenuEdit: MenuEdit,
    },
    mounted(){
        this.getMenus();
    },
    data(){
        return {
            query:{
                url: null,
                name: null
            },
            showQuery: false,
            treeConfig: {
                children: "children",
                line: true,
            },
            columns: [
                {field: "name", title: this.msg("菜单名"), width:250, treeNode: true},
                {field: "id", title: this.msg("ID"), width: 80},
                {field: "url", title: this.msg("URL"), minWidth:180, },
                {field: "clazz", title: this.msg("CSS类名"), width:100,
                    slots: {
                        default: ({ row }) => {
                            return [
                                <span class={'iconfont '+row.clazz}></span>
                            ]
                        }
                    }
                },
                {field: "style", title: this.msg("Style样式"), width:100},
                {field: "seq", title: this.msg("排序"), width:80},
            ],
            menus: [],      //菜单列表
            origin: [],     //编辑的列表
            rootName: this.msg("根菜单"),
            currMenu:  {id:0, name: this.rootName, parentId: 0},
            root: {id:0, name:this.rootName,parentId: 0},

            dgTitle: '',
            dgVisible: false,
            parent: null,

        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                return this.$store.state.isMinScreen;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    methods:{
        getMenus(){
            this.$axios.post(this.$kit.api.menu.all, this.query)
                .then((result) => {
                    if(result.status){
                        var data = result.data;
                        //this.buildTree(data);
                        
                        var menus = this.$kit.buildTree(data, true);
                        var root = [{id:0, name:this.rootName, parentId:0, children: menus}];

                        this.menus = menus;
                        this.origin = root; 
                    }
                });
        },
        reset(){
            this.$kit.clearQuery(this.query);
        },
        doAdd(){
            var row = this.$refs.dataTable.getCurrentRecord();
            if(row){
                this.parent = row;
            }else{
                this.parent = this.root;
            }

            this.currMenu = {
                name: null,
                parentId: 0,
                url: null,
                style: null,
                clazz: null,
                seq: null
            };

            this.dgTitle = this.msg("新增菜单");
            this.dgVisible = true;

        },
        doEdit(){
            var row = this.$refs.dataTable.getCurrentRecord();
            if(!row){
                this.$message(this.msg('请选中数据后在进行操作'));
                return;
            }

            if(row.id==0){
                return ;
            }
            if(row.parentId==0){
                this.parent = this.root;
                this.currMenu = row;
            }else{
                this.parent = row.parent;

                var menu = {};
                for(var key in row){
                    if(key != 'parent'){
                        menu[key] = row[key];
                    }
                }
                this.currMenu = menu;
            }

            this.dgTitle = this.msg("编辑菜单")+":"+row.name;
            this.dgVisible = true;

        },
        doDelete(){
            var row = this.$refs.dataTable.getCurrentRecord();
            if(!row){
                this.$message(this.msg('请选中数据后在进行操作'));
                return;
            }

            var _this = this;
            this.$confirm(this.fmt('确定要删除菜单《%s》吗?', [row.name]), this.msg('warning'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.get(_this.$kit.api.menu.delete+"?id="+row.id)
                .then((result) => {
                    _this.$message(result.msg || _this.msg('删除成功'));
                    _this.getMenus(true);
                });
            }).catch((err) => {
                // console.log(err);
            });
        },
        closeEditDialog(type){  //编辑弹窗关闭后的回调事件
            if(type){
                this.getMenus();
            }
            this.dgVisible = false;
        },
    }
    
}
</script>

<style scoped lang="scss">

</style>
