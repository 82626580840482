<!--编辑组织架构-->
<template>
<el-form ref="form" :model="dept" label-width="80px" size="mini">
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('显示名')">
                <el-input v-model="dept.label"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('关键字')">
                <el-input v-model="dept.name"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
             <el-form-item :label="msg('上级组织')" >
                <el-input v-model="dept.parentLabel" v-popover:deptsPopover suffix-icon="el-icon-arrow-down" :readonly="true"></el-input>
            </el-form-item>
        </el-col>
    </el-row>

    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>

    <el-popover
            ref="deptsPopover"
            placement="bottom"
            width="300"
            trigger="focus"
            v-model="deptsPopoverVisiable"
        >
        <div>
            <el-input
                :placeholder="msg('输入关键字进行过滤')" clearable size="mini"
                    v-model="deptFilterText">
            </el-input>
            <el-scrollbar style="height:400px;">
                <el-tree :data="depts" node-key="id" 
                    ref="deptTreeRef"
                    :default-expanded-keys="expandKeys"
                    :filter-node-method="filterNode"
                    @node-click="selectedDeptNode">
                </el-tree>
            </el-scrollbar>
        </div>
        
    </el-popover>
</el-form> 
</template>

<script>
export default {
    name: 'DeptEdit',
    data(){
        return{
            deptsPopoverVisiable:false,
            deptFilterText: null,
        }
    },
    props:{
        depts:Array,
        expandKeys: Array,
        dept: Object,
    },
    watch: {
        deptFilterText(val) {
            this.$refs.deptTreeRef.filter(val);
        }
    },
    methods:{
        onSubmit(){

            var url = null;
            if(this.dept.id==null){
                url = this.$kit.api.dept.create;
            }else{
                url = this.$kit.api.dept.update;
            }

            let tempDept = {
                id: this.dept.id,
                parentId: this.dept.parentId,
                name: this.dept.name,
                label: this.dept.label,
            };
            delete tempDept.children;

            var _this = this;
            this.$axios.post(url, tempDept)
                .then(function(result){
                    if(result.status){
                        _this.$message(result.msg || (_this.dept.id==null? _this.msg("创建成功"): _this.msg('更新成功')));
                        if(result.status){
                            _this.$emit("close-dg", true);
                        }
                    }else{
                        _this.$message(result.msg || (_this.dept.id==null? _this.msg('创建失败'):_this.msg('更新失败')));
                    }
                
                });
        },
        selectedDeptNode(dept, node, self){
            this.dept.parentId = dept.id;
            this.dept.parentLabel = dept.label;

            this.deptsPopoverVisiable = false;
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
    }
}
</script>

<style scoped lang="scss">
.icon-list{ margin:0px; padding:0px; display:flex; flex-wrap:wrap;  border: 1px solid #eaeefb;}
.icon-list li{cursor:pointer;
        padding:8px; border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
        display:flex; flex-direction: column; justify-content: center; align-items:center;
}
.icon-list li i{font-size:18px;} 
.icon-list li:hover{color:#5CB6FF;}


</style>
