<!--给节点分配仓库-->
<template>
<el-row class="wrap-panel">
    <span class="plabel">{{msg('仓库')}}</span>
    <el-select v-model="dept.storeId"  placeholder="请选择" size="mini"
        clearable
    >
        <el-option
            v-for="item in stores"
                :key="item.id"
                :label="item.name"
                :value="item.id">
        </el-option>
    </el-select>
    <div style="text-align:center; margin-top:20px;">
        <el-button type="primary" size="mini"  @click="doSubmint"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>
</el-row>  
</template>

<script>
export default {
    name: 'DeptGrantStore',
    mounted: function(){
        this.getStores();
    },
    data(){
        return {
            stores: null,
        }
    },
    props: {
        dept: Object,
    }, 
    methods:{
        getStores(){
            this.$axios.post(this.$kit.api.store.list, {})
                .then((result)=> {
                    if(result.status){
                        this.stores = result.data.list;
                    }
                });
        },
        doSubmint(){
            this.$axios.get(this.$kit.api.dept.grantStore+"?storeId="+this.dept.storeId+"&deptId="+this.dept.id)
                .then((result)=>{
                    if(result.status){
                        this.$message(result.msg || this.msg("更新成功"));
                        if(result.status){
                            this.$emit("close-dg", true);
                        }
                    }else{
                        this.$message(result.msg || this.msg('更新失败'));
                    }
                });
        }
    }
}
</script>
<style lang="scss" scoped>
.plabel{display:inline-block; margin-left: 15px; margin-right:10px;}
.pintpu{width:300px; max-width:300px;}
</style>