<template>
<el-form ref="form" :model="dept" label-width="120px">
    <el-row :xs="24" sm="12">
        <el-col :xs="24" :sm="18">
            <el-form-item :label="msg('系统版本')">
                <el-select v-model="dept.version" placeholder="请选择">
                    <el-option
                    v-for="item in versions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="18">
            <el-form-item :label="msg('系统名称')">
                <el-input v-model="dept.company"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="18">
            <el-form-item :label="msg('系统标题')">
                <el-input v-model="dept.title"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="18">
          <el-form-item :label="msg('工单类型')">
            <el-input v-model="dept.orderTypes"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="18">
            <el-form-item :label="msg('工单联系人')">
                <el-input v-model="dept.users"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="18">
            <el-form-item label="联系方式1">
                <el-input v-model="dept.remark1"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="18">
            <el-form-item label="联系方式2">
                <el-input v-model="dept.remark2"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="18">
            <el-form-item label="联系方式3">
                <el-input v-model="dept.remark3"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="18">
            <el-form-item :label="msg('系统logo')">
                <div style="display:flex;">
                    <el-upload class="avatar-uploader" :action="uploadUrl"
                            :show-file-list="false"
                            :with-credentials="true"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
            </el-form-item>
        </el-col>
    </el-row>
    
    <div style="text-align:center;margin-top: 15px;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>

</el-form> 
</template>

<script>
export default {
  name: 'ProductEdit',
  data(){
    return{
        uploadUrl: this.$kit.api.upload+"?type=company&press=0",
        versions: [{
          value: '',
          label: '空'
        }, {
          value: '会员版',
          label: '会员版'
        }, {
          value: '标准版',
          label: '标准版'
        }, {
          value: '集运版',
          label: '集运版'
        }],
    }
  }, 
  computed:{
    imageUrl:{
          get(){
              if(this.dept.logo){
                  return this.$kit.api.imgView+this.dept.logo;
              }
              return null;
          }
      }
  },
  props:{
        id:Number,
        dept: Object,
  },
  mounted: function(){
  },
  methods:{
    init(){},
    onSubmit(){
        let param = {
            id:this.id,
            company:this.dept.company,
            title:this.dept.title,
            logo:this.dept.logo,
            orderTypes:this.dept.orderTypes,
            users:this.dept.users,
            remark1:this.dept.remark1,
            remark2:this.dept.remark2,
            remark3:this.dept.remark3,
            version:this.dept.version
        }
        this.$axios.post(this.$kit.api.dept.updateCompany,param)
            .then((result) => {
                    if(result.status){
                        this.$emit("close-dg", true);
                    }
                    this.$message(result.msg);
                });
    },
    handleAvatarSuccess(res, file,fileList) {
        if(res.status){
            this.dept.logo = res.data;
        }else{
            this.$message(res.msg || this.msg("上传失败"));
        }
    },
    beforeAvatarUpload(){
        return true;
    },
  }
}
</script>


<style scoped lang="scss">
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 64px;
        height: 64px;
        line-height: 64px;
        text-align: center;
    }
    .avatar {
        width: 64px;
        height: 64px;
        display: block;
    }

.w-e-toolbar{flex-wrap: wrap;}
.icon-list{ margin:0px; padding:0px; display:flex; flex-wrap:wrap;  border: 1px solid #eaeefb;}
.icon-list li{cursor:pointer;
    padding:8px; border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    display:flex; flex-direction: column; justify-content: center; align-items:center;
}
.icon-list li i{font-size:18px;} 
.icon-list li:hover{color:#5CB6FF;}
.upload-icon .el-form-item__content{display:flex;}
.upload-icon .el-form-item__content >*:last-child{margin-left:10px;}
</style>
