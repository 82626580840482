import { render, staticRenderFns } from "./DeptEdit.vue?vue&type=template&id=49b0a2d1&scoped=true"
import script from "./DeptEdit.vue?vue&type=script&lang=js"
export * from "./DeptEdit.vue?vue&type=script&lang=js"
import style0 from "./DeptEdit.vue?vue&type=style&index=0&id=49b0a2d1&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49b0a2d1",
  null
  
)

export default component.exports